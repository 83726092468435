import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/application',
    name: 'application',
    component: () =>
      import('../views/promote/application.vue')
  },
  {
    path: '/getVideoSrc',
    name: 'getVideoSrc',

    component: () =>
      import('../views/cms/getVideoSrc.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () =>
      import('../views/agreement/privacyPolicyForApp.vue'),
    meta: { hidden: true, title: 'APP隐私政策' }
  },
  {
    path: '/protocolClinic',
    name: 'protocolClinic',
    component: () =>
      import('../views/agreement/protocolClinic.vue'),
    meta: { hidden: true, title: '莲藕医生隐私政策' }
  },
  {
    path: '/medicineDigitalTravel',
    name: 'medicineDigitalTravel',
    component: () =>
      import('../views/digital/medicineDigitalTravel.vue'),
    meta: { hidden: true, title: '医药数字化万里行' }
  },
  {
    path: '/video',
    name: 'video',
    component: () =>
      import('../views/digital/video.vue'),
    meta: { hidden: true, title: '' }
  },
  {
    path: '/view',
    name: 'view',
    component: () =>
      import('../views/dataView/view.vue'),
    meta: { hidden: true, title: '数据可视化' }
  },
  {
    path: '/openPlatform',
    name: 'openPlatform',
    component: () =>
      import('../views/openPlatform/index.vue'),
    meta: { hidden: true, title: '开放平台' }
  },
  // {
  //   path: '/api',
  //   name: 'api',
  //   component: () =>
  //     import('../views/openPlatform/api.vue'),
  //   meta: { hidden: true, title: '接口文档' }
  // },
  {
    path: '/applyAccess',
    name: 'applyAccess',
    component: () =>
      import('../views/openPlatform/applyAccess.vue'),
    meta: { hidden: true, title: '申请接入' }
  },
  {
    path: '/propagandaPage',
    name: 'propagandaPage',
    component: () =>
      import('../views/networkConsultingRoom/propagandaPage.vue'),
    meta: { hidden: true, title: '网络诊室宣传页' }
  }

  // {
  //   path: '/applicationWeb',
  //   name: 'applicationWeb',
  //   component: () =>
  //     import('../views/promote/applicationWeb.vue')
  // },
  // {
  //   path: '/mobile',
  //   name: 'mobile',
  //   component: () =>
  //     import('../views/promote/mobile.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
