import { post } from './require'

const promoteApi = {
  AddInternetHospitalMessage: (params) => post('/api/v2/Management/AddInternetHospitalMessage', params, 'baseapi'),
  GetMessageToken: (params) => post('/api/v2/Management/GetMessageToken', params, 'baseapi'),
  AddOfficialWebsiteMessage: (params) => post('/api/v2/Management/AddOfficialWebsiteMessage', params, 'baseapi'),
  AddCooperationCommonMessage: (params) => post('/api/v2/Management/AddCooperationCommonMessage', params, 'baseapi')
}

export default promoteApi
