import promote from './promote'
import cms from './cms'
import dataView from './dataView'
import wlzsApi from './wlzs'
export default {
  ...promote,
  ...cms,
  ...dataView,
  ...wlzsApi
}
